<template>
    <b-card :ref="sentinalName">
        <div class="mb-4">
            <div v-if="reportType === 'documents'">
                <h5>{{ $t('ARCHIVE.REPORTS.WORKSPACE_PROGRESS_ANNUAL.TITLE_DOC', {period})  }}</h5>
                <small>{{ $t('ARCHIVE.REPORTS.WORKSPACE_PROGRESS_ANNUAL.TITLE_DESCRIPTION_DOC') }}</small>
            </div>
            <div v-if="reportType === 'dimension'">
                <h5>{{ $t('ARCHIVE.REPORTS.WORKSPACE_PROGRESS_ANNUAL.TITLE_DIMENSION', {period}) }}</h5>
                <small>{{ $t('ARCHIVE.REPORTS.WORKSPACE_PROGRESS_ANNUAL.TITLE_DESCRIPTION_DIMENSION') }}</small>
            </div>
        </div>
        <div class="d-flex justify-content-end my-10">
            <st-date-range
                v-model="dateRange"
                startField="start"
                endField="end"
                @change="onPeriodUpdate(dateRange)"
                :formatOptions="dateFormatOptions"
            />
        </div>
        <div class="my-4 total-report-section" v-if="reportType === 'documents' && periodTotalDocuments > 0">
            <h2> {{periodTotalDocuments}}</h2>
            <small>{{ $t('ARCHIVE.REPORTS.WORKSPACE_PROGRESS_ANNUAL.TOTAL_DESCRIPTION_DOC') }}</small>
        </div>
        <div class="my-4 total-report-section" v-if="reportType === 'dimension' && periodTotalDimension > 0">
            <h2> {{periodTotalDimension}} MB</h2>
            <small>{{ $t('ARCHIVE.REPORTS.WORKSPACE_PROGRESS_ANNUAL.TOTAL_DESCRIPTION_DIMENSION') }}</small>
        </div>
        <div id="chart">
            <apexchart type="line" height="200" :options="options" :series="series"></apexchart>
        </div>
    </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { bytesConvertor } from '@/core/helpers/globalMethods';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
export default {
    name: 'WorkspaceFoldersDimensions',
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        workspaces: {
            type: Array,
            required: true,
        },
        reportType: {
            type: String,
            required: true,
        },
        periodType: {
            type: String,
            default: 'annually'
        },
        sentinalName: {
            type: String,
            required: false,
        },
    },
    data() {
        const now = new Date();
        const today = moment(now).format('YYYY-MM-DD');
        const startYear = moment(now).subtract(4, 'years').format('YYYY-MM-DD');
        const startMonth = moment(now).subtract(3, 'months').format('YYYY-MM-DD');
        return {
            dateRange: {
                start: '',
                end: today
            },
            periodTotalDocuments: 0,
            periodTotalDimension: 0,
            series: [],
            options: {},
            dateFormatOptions: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            },
            todayDate: today,
            startYearDate: startYear,
            startMonthDate: startMonth,
            isIntersectingElement: false,
        }
    },
    computed: {
        ...mapGetters({
            periodReport: 'archive/workspaces/periodReport',
        }),
        workspacesList() {
            return this.workspaces.map((workspace) => workspace.realName)
        },
        period() {
            let period;
            switch (this.periodType) {
                case 'annually':
                    period = this.$t('ARCHIVE.PERIOD.ANNUALY');
                    break;
                case 'monthly':
                    period = this.$t('ARCHIVE.PERIOD.MONTHLY');
                    break;
                case 'weekly':
                    period = this.$t('ARCHIVE.PERIOD.MONTHLY');
                    break;
                default:
                    period = ''
            }
            return period;
        }
    },
    watch: {
        isIntersectingElement(value) {
            this.$emit("on-intersection-element", value, this.sentinalName);
        },
    },
    mounted() {
        if (this.sentinalName) {
            const sentinal = this.$refs[this.sentinalName];
            const handler = (entries) => {
                if (entries[0].isIntersecting) {
                    this.isIntersectingElement = true;
                    if (this.periodType === 'monthly') {
                        this.dateRange.start = this.startMonthDate;
                        this.onPeriodUpdate({
                            start: this.startMonthDate,
                            end: this.todayDate,
                        })
                    }
                    if (this.periodType === 'annually') {
                        this.dateRange.start = this.startYearDate;
                        this.onPeriodUpdate({
                            start: this.startYearDate,
                            end: this.todayDate,
                        })
                    }
                    observer.unobserve(sentinal);
                } else {
                    this.isIntersectingElement = false;
                }
            };
            const observer = new window.IntersectionObserver(handler);
            observer.observe(sentinal);
        }
    },
    methods: {
        ...mapActions({
            doFilterStorage: 'archive/workspaces/filterStorage',
        }),
        onPeriodUpdate(date) {
            if (date.start && date.end) {
                const startYear = new Date(date.start).getFullYear();
                const endYear = new Date(date.end).getFullYear();
                const startMonth = new Date(date.start).getMonth();
                const endMonth = new Date(date.end).getMonth();
                const yAxisTitleText = this.reportType === 'documents' ?
                                this.$t('ARCHIVE.REPORTS.WORKSPACE_PROGRESS_ANNUAL.Y_AXIS_DOC') :
                                this.$t('ARCHIVE.REPORTS.WORKSPACE_PROGRESS_ANNUAL.Y_AXIS_DIMENSION');
                let payload = {
                    startYear,
                    endYear,
                    workspaceRealName: this.workspacesList,
                    raportType: 'annually'
                }
                let titleText = `${this.$t('ARCHIVE.REPORTS.WORKSPACE_PROGRESS_ANNUAL.CHART_TITLE')}`;
                if (this.periodType === 'monthly') {
                    titleText += ` (${startYear}.${startMonth.toString().padStart(2, '0')} -
                                    ${endYear}.${endMonth.toString().padStart(2, '0')})`;
                    payload = {
                        ...payload,
                        raportType:'monthly',
                        startMonth: startMonth + 1,
                        endMonth: endMonth + 1
                    }
                }
                if (this.periodType === 'annually') {
                    titleText += ` (${startYear} - ${endYear})`;
                }
                this.doFilterStorage(payload)
                .then(()=> {
                    const series = [];
                    let totalDimension = 0;
                    let totalDocuments = 0;
                    this.periodReport.items.forEach((report) => {
                        let data = [];
                        let  totalDimensionsValues = 0;
                        let  totalDocumentsValues = 0;
                        report.series.forEach((item) => {
                            if (item.name === 'dimension' && this.reportType === 'dimension') {
                                item.values.forEach((value) => {
                                    totalDimensionsValues += value;
                                    const workspaceDimension = bytesConvertor(value, 'MB', 2);
                                    data.push(workspaceDimension);
                                })
                            }
                            if (item.name === 'document' && this.reportType === 'documents') {
                                item.values.forEach((value) => {
                                    totalDocumentsValues += value;
                                })
                                data = item.values;
                            }
                        })
                        totalDimension += totalDimensionsValues;
                        totalDocuments += totalDocumentsValues;
                        series.push({
                            name: report.name,
                            type: 'line',
                            data
                        })
                    })

                    this.periodTotalDimension = bytesConvertor(totalDimension, 'MB', 2);
                    this.periodTotalDocuments = totalDocuments;
                    this.series = series;

                    this.options = {
                        chart: {
                            height: 350,
                            type: 'line',
                            stacked: false
                        },
                        dataLabels: {
                            enabled: true
                        },
                        stroke: {
                            width: [1, 1, 4]
                        },
                        title: {
                            text: titleText,
                            align: 'center',
                            offsetX: 0,
                            offsetY: 10,
                        },
                        xaxis: {
                            categories: this.periodReport.categories,
                        },
                        yaxis: {
                            opposite: true,
                            title: {
                                text: yAxisTitleText
                            },
                        },

                        tooltip: {
                            fixed: {
                                enabled: false,
                                position: 'topLeft',
                                offsetY: 30,
                                offsetX: 60
                            },
                        },
                        legend: {
                            position: 'left',
                            horizontalAlign: 'left',
                            offsetX: -35,
                            offsetY: 150,
                        },
                    }
                })
            }
        },
        setPeriod(){
            if (this.periodType === 'monthly') {
                this.dateRange.start = this.startMonthDate;
                this.onPeriodUpdate({
                    start: this.startMonthDate,
                    end: this.todayDate,
                })
            }
            if (this.periodType === 'annually') {
                this.dateRange.start = this.startYearDate;
                this.onPeriodUpdate({
                    start: this.startYearDate,
                    end: this.todayDate,
                })
            }
        }
    }
}
</script>
