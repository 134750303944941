<template>
    <b-card>
        <div class="mb-4">
            <h5>{{ $t('ARCHIVE.REPORTS.WORKSPACE_FOLDERS_DIMENSION.TITLE') }}</h5>
            <small>{{ $t('ARCHIVE.REPORTS.WORKSPACE_FOLDERS_DIMENSION.DESCRIPTION') }}</small>
        </div>
        <div class="row row-cols-3">
            <div class="col">
                <div class="form-group">
                    <label>
                        {{ $t('ARCHIVE.REPORTS.WORKSPACE_LABEL') }}
                    </label>
                    <st-autocomplete
                        v-model="workspace"
                        :options="workspacesList"
                        name="workspaces"
                    >
                    </st-autocomplete>
                </div>
            </div>
        </div>
        <div class="my-10">
            <h2 class="text-center">{{totalDimension}} MB</h2>
        </div>
        <div id="chart">
            <div v-if="totalDimension > 0">
                <p class="ml-4 h6 ">
                    {{ $t('ARCHIVE.REPORTS.WORKSPACE_FOLDERS_DIMENSION.CHART_LABELS_TITLE') }}
                </p>
            </div>
            <apexchart width="100" :options="options" :series="series"></apexchart>
        </div>
    </b-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'WorkspaceFoldersDimensions',
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        workspaces: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            workspace: '',
            series: [],
            totalDimension: 0,
            options: {
                chart: {
                    width: '75%',
                    type: 'pie',
                },
                labels: [],
                legend: {
                    position: 'left'
                },
                theme: {
                    mode: 'light',
                    palette: 'palette3',

                },
            },
        }
    },
    computed: {
        ...mapGetters({
            folders: 'archive/subfolders/rows',
        }),
        workspacesList() {
            return this.workspaces.map((workspace) => ({
                value: workspace.realName,
                text: workspace.name,
            }))
        }
    },
    watch: {
        workspace(value) {
            this.doFetchFolders({payload: {offset: 0, pageSize: 100}, id: value})
            .then(()=> {
                let totalDimension = 0;
                const foldersSeries = [];
                const labels = [];
                const mb = 1048576;

                this.folders.forEach((folder) => {
                    totalDimension += folder.dimension;
                    const folderDimension = parseFloat((folder.dimension / mb).toFixed(2));
                    foldersSeries.push(folderDimension);
                    labels.push(folder.name);
                })

                this.totalDimension = parseFloat((totalDimension / mb).toFixed(2));
                this.options = {...this.options, labels};
                this.series = foldersSeries;
            })
        }
    },
    methods: {
        ...mapActions({
            doFetchFolders: 'archive/subfolders/getSubfolders',
        }),
        doSelectFirstWorkspace() {
            this.workspace = this.workspaces[0].realName;
        }
    }
}
</script>
